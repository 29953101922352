import apis from "@/api/api";
import { ViewTable, Status, Dialog, Layout, Button, Filter } from "@/components";
import React from "react";
import SupplierForm from './SupplierForm';
import WebApi from "@/web/api";
import { LibAuthCode } from "lib/Auth";

export default class SupplierPage extends React.PureComponent {
  private table = React.createRef<ViewTable>()

  private form = React.createRef<SupplierForm>()

  private updateForm = React.createRef<SupplierForm>()

  private reqData = {
    keyword: ''
  }

  private initValues: any= {};

  state = {
    visible: false,
    visible2: false,
  }

  render() {
    return <Layout.Page>
      <Layout.Toolbar title="供应商列表">
        <Button authCode={LibAuthCode.supplier.create} type="primary"
          onClick={() => {
            this.setState({
              visible: true
            });
          }}>新增供应商</Button>
        <Button.Refresh onClick={() => {
          this.table.current?.refresh();
        }}/>
      </Layout.Toolbar>
        
      <ViewTable
        flex1
        pagination={false}
        ref={this.table}
        columns={[
          {
            title: '编号',
            width: 200,
            align: 'center',
            dataIndex: 'supplierId',
          },
          {
            title: '名称',
            dataIndex: 'supplierName',
            width: 200,
          },
          {
            title: '状态',
            width: 150,
            align: 'center',
            dataIndex: 'status',
            render: (value) => {
              return <Status status={value}></Status>
            },
          },
          // {
          //   title: '默认',
          //   width: 100,
          //   align: 'center',
          //   dataIndex: 'isDefault',
          //   render: (value) => {
          //     return value === '1' ? <Tag color="green">是</Tag> : <Tag>否</Tag>;
          //   },
          // },
          // {
          //   title: '描述',
          //   dataIndex: 'supplierDesc',
          //   width: 200,
          // },
          {
            title: '联系人',
            dataIndex: 'linkman',
            width: 150,
          },
          {
            title: '手机号码',
            dataIndex: 'mobile',
            width: 200,
            align: 'center'
          },
          {
            title: '地址',
            dataIndex: 'address',
            width: 300,
          },
          {
            title: '创建时间',
            dataIndex: 'createdAt',
            align: 'center',
            width: 200
          },
        ]}
        fetch={data => {
          return WebApi.supplier_list()
        }}
        actionButtons={[
          {
            type: 'update',
            authCode: LibAuthCode.supplier.update,
            onClick: row => {
              this.initValues = row;
                this.setState({
                  visible2: true
                });
            }
          }
        ]}
      />

      <Dialog full={false} title="新增供应商" visible={this.state.visible}
        onOk={this.clickCreate}
        destroyOnClose
        onCancel={() => this.setState({visible: false})}>
        <SupplierForm ref={this.form} initialValues={{status: '1'}}/>
      </Dialog>

      <Dialog full={false} title="修改供应商" visible={this.state.visible2}
        destroyOnClose
        onOk={this.clickUpdate}
        // destroyOnClose
        onCancel={() => this.setState({visible2: false})}>
        <SupplierForm ref={this.updateForm} initialValues={this.initValues}/>
      </Dialog>
    </Layout.Page>
  }

  clickSetDefault = async row => {
    Dialog.confirm({
      title: '设置确认',
      content: '确定设置为默认供应商吗？',
      onOk: () => {
        apis["supplier.setDefault"].post({
          supplierId: row.id,
        }).then(() => {
          showSuccess.save();
          this.table.current?.reset(this.reqData);
        })
      }
    });
  }

  clickUpdate = async () => {
    try {
      const values = await this.updateForm.current?.validateFields();
      
      WebApi.supplier_update({
        supplierId: this.initValues.id,
        ...values
      })
      .then(() => {
        showSuccess.save();
        this.setState({visible2: false});
        this.table.current?.reset(this.reqData);
      })
    } catch(e) {
      console.error(e);
    }
  }

  clickDelete = (row) => {
    Dialog.confirm({
      title: '删除确认',
      content: `确定删除供应商“${row.supplierName}”吗？`,
      onOk: () => {
        WebApi.supplier_delete({
          supplierId: row.id,
        }).then(() => {
          showSuccess.delete();
          this.table.current?.reset(this.reqData);
        })
      }
    });
  }

  clickCreate = async () => {
    try {
      const values: any = await this.form.current?.validateFields();
      
      WebApi.supplier_create(values)
      .then(() => {
        showSuccess.save();
        this.setState({visible: false});
        this.table.current?.reset(this.reqData);
      })
    } catch(e) {
      console.error(e);
    }
  }
}