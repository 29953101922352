import React from 'react';
import { Form, Input, Radio, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';

export default class SupplierForm extends React.PureComponent<{
  initialValues?: object
}> {

  private form: FormInstance | null = null
  render() {
    const {initialValues} = this.props;
    return <div>
      <Form labelCol={{span: 4}} ref={r => this.form = r} initialValues={
        {
          status: '1',
          ...initialValues
        }
      }>
        <Form.Item label="名称" name="supplierName" rules={[
          {
            required: true,
            message: '请填写供货商名称'
          }
        ]}>
          <Input placeholder="请填写供货商名称"/>
        </Form.Item>
        {/* <Form.Item label="描述" name="supplierDesc" rules={[
          {
            required: true,
            message: '请填写供货商描述'
          }
        ]}>
          <Input placeholder="请填写供货商描述"/>
        </Form.Item> */}
        <Form.Item label="联系人" name="linkman" rules={[
          {
            required: true,
            message: '请填写联系人'
          }
        ]}>
          <Input placeholder="请填写联系人"/>
        </Form.Item>
        <Form.Item label="手机号码" name="mobile" rules={[
          {
            required: true,
            message: '请填写手机号码'
          }
        ]}>
          <Input placeholder="请填写手机号码" maxLength={11}/>
        </Form.Item>
        <Form.Item label="地址" style={{marginBottom: 0}} name="address" rules={[
          {
            required: true,
            message: '请填写详细地址'
          }
        ]}>
          <Input placeholder="请填写详细地址"/>
        </Form.Item>
        {/* <Form.Item label="状态" name="status" rules={[
          {
            required: true,
            message: '请选择启用或禁用'
          }
        ]}>
          <Radio.Group>
            <Radio value="1">正常</Radio>
            <Radio value="0">禁用</Radio>
          </Radio.Group>
        </Form.Item> */}
      </Form>
    </div>
  }
  validateFields = async () => {
    return await this.form?.validateFields();
  }

  setFieldsValue = (values) => {
    this.form?.setFieldsValue(values);
  }
}